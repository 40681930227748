import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Grid, Box, Card, CardContent, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/streamsentinel-logo.png'; 
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

function Storefront() {
  const navigate = useNavigate();
  const [pricingType, setPricingType] = useState('monthly');  // Pricing state
  const [userId, setUserId] = useState(null); // State to hold the userId
  const auth = getAuth();

  useEffect(() => {
    // Listen for changes in the authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null); // User is not logged in
      }
    });

    // Clean up subscription on component unmount
    return () => unsubscribe();
  }, [auth]);

  // Subscription function triggered on plan selection
  const handleSubscription = async (priceType) => {
    if (!userId) {
      alert('Please login first.');
      return;
    }

    try {
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSessionProxy');
      
      // Call the Cloud Function to create Stripe session
      const { data } = await createCheckoutSession({
        priceId: priceType,
        success_url: window.location.origin + '/success',
        cancel_url: window.location.origin + '/cancel',
        appVersion: 'v1',  // Example app version
      });

      if (data.sessionId) {
        // Redirect to Stripe checkout page
        window.location.href = `https://checkout.stripe.com/pay/${data.sessionId}`;
      }
    } catch (error) {
      console.error('Error starting the checkout process:', error);
      alert('Failed to start the checkout process.');
    }
  };

  const drawerWidth = 240; // Sidebar width

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0d0d0d, #1a1a1a, #252525)',
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
            color: 'white',
            borderRight: '1px solid rgba(230, 57, 70, 0.8)',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem button onClick={() => navigate('/')}>
            <ListItemText primary="Home Page" />
          </ListItem>
          <ListItem button onClick={() => navigate('/subscribe')}>
            <ListItemText primary="Subscribe" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/dashboard/${userId}`)}>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigate('/aboutme')}>
            <ListItemText primary="About Me" />
          </ListItem>
          <ListItem button onClick={() => navigate('/contact')}>
            <ListItemText primary="Contact" />
          </ListItem>
          <ListItem button onClick={() => navigate('/releases')}>
            <ListItemText primary="Release Updates" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/overlay/${userId}`)}>
            <ListItemText primary="Camo Overlay" />
          </ListItem>
        </List>
        <Box sx={{ position: 'absolute', bottom: 0, padding: 2, textAlign: 'center' }}>
          <img
            src={logo}
            alt="StreamSentinel Logo"
            style={{
              maxWidth: '70%',
              marginBottom: '10px',
              filter: 'drop-shadow(1px 1px 3px rgba(255, 255, 255, 0.8))',
            }}
          />
          <List>
            <ListItem button onClick={() => navigate('/login')}>
              <ListItemText primary="Login" sx={{ color: '#e63946' }} />
            </ListItem>
            <ListItem button onClick={() => navigate('/signup')}>
              <ListItemText primary="Create Account" sx={{ color: '#e63946' }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Container
          maxWidth="lg"
          sx={{
            background: 'rgba(29, 29, 29, 0.9)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 4,
            borderRadius: '12px',
          }}
        >
          <Typography variant="h2" sx={{ color: '#e63946', textAlign: 'center', mb: 2 }}>StreamSentinel by Sigurd (NimbusTech)</Typography>
          <Typography variant="subtitle1" paragraph sx={{ textAlign: 'center', color: '#d4d4d4' }}>
            StreamSentinel monitors real-time metrics for your computer, network, and OBS! Get real-time alerting via email or Discord for professional subscribers.
          </Typography>

          <Box sx={{ position: 'relative', width: '100%', maxWidth: '800px', pt: '56.25%', mb: 3 }}>
            <iframe
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              src="https://www.youtube.com/embed/8A-u6iYlN6M?si=gEB5gGYPeoB5iN6V"
              title="Stream Sentinel Showcase"
              allowFullScreen
            />
          </Box>

          {/* Pricing Table */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <Button
              variant={pricingType === 'monthly' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setPricingType('monthly')}
            >
              Monthly
            </Button>
            <Button
              variant={pricingType === 'yearly' ? 'contained' : 'outlined'}
              color="secondary"
              onClick={() => setPricingType('yearly')}
              sx={{ ml: 2 }}
            >
              Yearly
            </Button>
          </Box>

          <Typography variant="h4" align="center">Choose Your Plan</Typography>
          <Grid container spacing={4} justifyContent="center" sx={{ maxWidth: '1200px', marginTop: '32px' }}>
            {/* Premium Plan */}
            <Grid item xs={12} md={4}>
              <Card sx={{ boxShadow: 4, backgroundColor: '#2c3e50', color: 'white' }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h5" gutterBottom>
                    Premium
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {pricingType === 'monthly' ? '$20/month' : '$200/year'}
                  </Typography>
                  <Typography sx={{ marginBottom: 2 }}>
                    Metric Monitoring, Email Alerts, Real-Time Dashboards
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSubscription(pricingType === 'monthly' ? 'price_1Q7QvzCSH4xkclLAncawNJq3' : 'price_1Q8P5rCSH4xkclLAmVHf4fJE')}
                  >
                    {pricingType === 'monthly' ? 'Choose Monthly ($20/month)' : 'Choose Yearly ($200/year)'}
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Professional Plan */}
            <Grid item xs={12} md={4}>
              <Card sx={{ boxShadow: 4, backgroundColor: '#34495e', color: 'white' }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h5" gutterBottom>
                    Professional
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {pricingType === 'monthly' ? '$30/month' : '$300/year'}
                  </Typography>
                  <Typography sx={{ marginBottom: 2 }}>
                    All Premium Features + More, Custom Discord Bot, Advanced Tools
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubscription(pricingType === 'monthly' ? 'price_1Q7QxRCSH4xkclLApvoc38OI' : 'price_1Q8P5aCSH4xkclLAREA2s5mn')}
                  >
                    {pricingType === 'monthly' ? 'Choose Monthly ($30/month)' : 'Choose Yearly ($300/year)'}
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Lifetime Plan */}
            <Grid item xs={12} md={4}>
              <Card sx={{ boxShadow: 4, backgroundColor: '#7f8c8d', color: 'white' }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h5" gutterBottom>
                    Lifetime License
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Coming Soon!
                  </Typography>
                  <Typography sx={{ marginBottom: 2 }}>Lifetime Access, Unlimited Support, Exclusive Updates</Typography>
                  <Button variant="contained" disabled>
                    Coming Soon
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {/* Setup Guide */}
        <Box sx={{ marginTop: 6, padding: 4, backgroundColor: 'rgba(0, 0, 0, 0.75)', borderRadius: '10px', color: 'white' }}>
          <Typography variant="h4" gutterBottom>StreamSentinel - Setup Guide</Typography>
          <Typography variant="body1" paragraph>
            Welcome to <strong>StreamSentinel</strong>, the all-in-one tool to monitor real-time metrics for your computer, network, and OBS. 
            This guide will walk you through the setup process.
          </Typography>
          <Typography variant="h5" gutterBottom>Installation</Typography>
          <Typography variant="body1" paragraph>
            Follow these steps to install the StreamSentinel application on your machine:
            <ol>
              <li>Download the Installer: Visit your StreamSentinel Dashboard and click on the <strong>Download</strong> link.</li>
              <li>Run the Installer and follow the prompts to complete the setup.</li>
              <li>Launch StreamSentinel from your Start Menu or desktop shortcut.</li>
            </ol>
          </Typography>
        {/* Technical Structure Section */}
          <Container sx={{ textAlign: 'center', marginTop: '50px', backgroundColor: '#1a1a1a', padding: '20px', borderRadius: '12px' }}>
          <Typography variant="h4" sx={{ color: '#e63946', fontWeight: 'bold', marginBottom: 3 }}>Technical Directory Structure</Typography>
          <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto' }}>
          <pre style={{ textAlign: 'left', color: '#e63946' }}>
            {`
              StreamSentinel/
              ├── app_logic/               # Main application logic
              │   └── main.py              # Entry point of the application
              ├── config/                  # Configuration files (e.g., Firebase)
              │   └── service_account.json  # Firebase Admin SDK credentials
              ├── discord_bot/             # Discord bot-related code
              │   └── discord_alerts.py    # Alert system for Discord
              ├── firebase/                # Firebase interaction logic
              │   └── firebase_manager.py  # Manages Firebase operations
              ├── metrics/                 # System metrics collection
              │   └── system_metrics.py    # Collects CPU, GPU, RAM, and network metrics
              ├── obs_ws_client/           # OBS WebSocket client for stream monitoring
              ├── optimize_obs/            # OBS optimization logic
              ├── ui/                      # User interface for metrics dashboard
              │   └── tier_selection.py    # UI for selecting tiers and metrics
            `}
          </pre>
          </Typography>
          </Container>

          {/* StreamSentinel Information Section */}
          <Container sx={{ textAlign: 'center', marginTop: '50px', backgroundColor: '#1a1a1a', padding: '20px', borderRadius: '12px' }}>
            <Typography variant="h4" sx={{ color: '#e63946', fontWeight: 'bold', marginBottom: 3 }}>StreamSentinel</Typography>
            <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto' }}>
             StreamSentinel is a monitoring tool designed to help streamers keep track of their system performance while streaming. It offers real-time monitoring of critical metrics such as CPU/GPU usage, temperature, RAM usage, and stream status (via OBS). For premium and professional tiers, it provides integration with Discord for alerting, as well as OBS optimization features.
            </Typography>

            <Typography variant="h5" sx={{ color: '#e63946', marginTop: 4, fontWeight: 'bold' }}>Table of Contents</Typography>
            <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
              <ul>
                <li>Features</li>
                <li>Installation</li>
                <li>Configuration</li>
                <li>Usage</li>
                <li>Directory Structure</li>
                <li>License Key System</li>
                <li>Development Setup</li>
                <li>Firebase Configuration</li>
                <li>Discord Bot Integration</li>
                <li>Testing</li>
                <li>Contributing</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ color: '#e63946', marginTop: 4, fontWeight: 'bold' }}>Features</Typography>
            <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
              <ul>
                <li>Real-Time Monitoring: Monitors CPU, GPU, RAM usage, and network metrics like latency and packet loss.</li>
                <li>Discord Alerts: Sends real-time alerts to your Discord channel when system metrics exceed predefined thresholds.</li>
                <li>OBS Studio Integration: Tracks your OBS streaming status and offers stream optimizations.</li>
                <li>Customizable Thresholds: Set thresholds for metrics like CPU usage, temperature, packet loss, etc.</li>
                <li>Multi-tier Subscription Model:
                  <ul>
                    <li>Free Tier: Basic system monitoring (CPU usage, RAM usage, and stream status).</li>
                    <li>Premium Tier: Includes additional metrics like CPU/GPU temperature and GPU usage.</li>
                    <li>Professional Tier: Includes Discord integration, full metric suite, and OBS optimizations.</li>
                  </ul>
                </li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ color: '#e63946', marginTop: 4, fontWeight: 'bold' }}>Installation</Typography>
            <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
              <strong>Prerequisites</strong><br />
              Python 3.9+<br />
              pip (Python package manager)<br />
              Firebase Project (for Firestore and authentication)<br />
              Discord Bot (for alerting)
            </Typography>
            <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
              <strong>Virtual Environment Setup</strong><br />
              It’s recommended to use a virtual environment to isolate your Python packages. To create and activate a virtual environment, run the following commands.
            </Typography>
          </Container>

          {/* Footer Links */}
          <Box sx={{ textAlign: 'center', padding: 2 }}>
            <Button color="inherit" onClick={() => navigate('/terms')}>Terms of Service</Button>
            <Button color="inherit" onClick={() => navigate('/privacy')}>Privacy Policy</Button>
          </Box>
      </Box>
    </Box>
  </Box>
  );
}
export default Storefront;