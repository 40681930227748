// camoTracker.js
import React, { useEffect, useState } from 'react';
import { ref, set, onValue, update } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { Box, Drawer, List, ListItem, ListItemText, Typography, FormControlLabel, Checkbox, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { db, analytics, logEvent } from '../firebase';
import logo from '../assets/images/streamsentinel-logo.png';

const drawerWidth = 240;

// Sanitization function to ensure Firebase-compatible keys
const sanitizeKey = (key) => key.replace(/[.#$/[\]]/g, '_');

function CamoTracker() {
  const [camoData, setCamoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();
  const user = auth.currentUser;
  const userId = user ? user.uid : null;

  useEffect(() => {
    if (userId) {
      const defaultCamos = {
        "Assault Rifle": {
          [sanitizeKey("XM4")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
          [sanitizeKey("AK-74")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
          [sanitizeKey("AMES 85")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
          [sanitizeKey("GPR 91")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
          [sanitizeKey("MODEL L")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
          [sanitizeKey("GOBLIN MK 2")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
          [sanitizeKey("AS VAL")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
        },
      "SMG": {
        [sanitizeKey("C9")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("KSV")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("TANTO .22")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("PP-919")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("JACKAL PDW")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("KOMPAKT 92")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
      },
      "Shotgun": {
        [sanitizeKey("MARINE SP")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("ASG-89")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
      },
      "Marksman Rifle": {
        [sanitizeKey("SWAT 5.56")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("Tsarkov 7.62")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("AEK-973")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("DM-10")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
      },
      "Pistol": {
        [sanitizeKey("9MM PM")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("Grekhova")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("GS45")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("Stryder .22")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
      },
      "Launcher": {
        [sanitizeKey("CIGMA 2B")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("HE-1")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
      },
      "Sniper": {
        [sanitizeKey("LW3A1 Frostline")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("SVD")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("LR 7.62")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
      },
      "Melee": {
        [sanitizeKey("Knife")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("Baseball Bat")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
      },
      "LMG": {
        [sanitizeKey("PU-21")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("XMG")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
        [sanitizeKey("GPMG-7")]: { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
      }
    };

    const camoRef = ref(db, `users/${userId}/camos`);
      const unsubscribe = onValue(camoRef, (snapshot) => {
        if (snapshot.exists()) {
          setCamoData(snapshot.val());
        } else {
          set(camoRef, defaultCamos);
          setCamoData(defaultCamos); // Initialize data if not present
        }
        setLoading(false);
      });

      return () => unsubscribe();
    }
  }, [userId]);

  const updateFirebaseDataLayer = (camoCounts) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'firebaseDataLayer',
        gold_count: camoCounts.gold_count,
        diamond_count: camoCounts.diamond_count,
        dark_spine_count: camoCounts.dark_spine_count,
        dark_matter_count: camoCounts.dark_matter_count,
      });
    }
  };

  const handleCheckboxChange = (category, gun, camoType) => async (event) => {
    const updatedValue = event.target.checked;
    const sanitizedGun = sanitizeKey(gun);
    const updatePath = `users/${userId}/camos/${sanitizeKey(category)}/${sanitizedGun}/${camoType}`;

    try {
      await set(ref(db, updatePath), updatedValue);
      setCamoData((prevData) => {
        const newCamoData = {
          ...prevData,
          [category]: {
            ...prevData[category],
            [sanitizedGun]: {
              ...prevData[category][sanitizedGun],
              [camoType]: updatedValue,
            },
          },
        };
        updateCamoProgress(newCamoData);
        return newCamoData;
      });
    } catch (error) {
      console.error('Error updating camo data:', error);
    }
  };

  const updateCamoProgress = async (updatedData) => {
    const camoCounts = {
      gold_count: 0,
      diamond_count: 0,
      dark_spine_count: 0,
      dark_matter_count: 0,
    };

    Object.keys(updatedData).forEach((category) => {
      Object.values(updatedData[category]).forEach((gunCamos) => {
        camoCounts.gold_count += gunCamos.Gold ? 1 : 0;
        camoCounts.diamond_count += gunCamos.Diamond ? 1 : 0;
        camoCounts.dark_spine_count += gunCamos['Dark Spine'] ? 1 : 0;
        camoCounts.dark_matter_count += gunCamos['Dark Matter'] ? 1 : 0;
      });
    });

    const progressRef = ref(db, `users/${userId}/progress`);
    await update(progressRef, camoCounts);
    updateFirebaseDataLayer(camoCounts);

    logEvent(analytics, 'camo_progress', {
      user_id: userId,
      gold_count: camoCounts.gold_count,
      diamond_count: camoCounts.diamond_count,
      dark_spine_count: camoCounts.dark_spine_count,
      dark_matter_count: camoCounts.dark_matter_count,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0a0a0a 20%, #121212 60%, #232323 100%)',
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
            color: 'white',
            borderRight: '1px solid rgba(230, 57, 70, 0.8)', // subtle red accent
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem button onClick={() => navigate('/')}>
            <ListItemText primary="Home Page" sx={{ color: '#e63946', fontWeight: 'bold' }} />
          </ListItem>
          {/* Additional links */}
        </List>
        <Box sx={{ paddingBottom: 2, textAlign: 'center' }}>
          <img src={logo} alt="StreamSentinel Logo" style={{ maxWidth: '70%', marginBottom: '10px', height: 'auto', filter: 'drop-shadow(1px 1px 3px rgba(255, 255, 255, 0.8))' }} />
        </Box>
      </Drawer>

      <Container sx={{ marginTop: 4, flexGrow: 1, color: 'white', padding: 4 }}>
        <Typography variant="h4" align="center" gutterBottom sx={{ color: '#e63946', fontWeight: 'bold', mb: 4 }}>
          Camo Tracker
        </Typography>
        {loading ? (
          <Typography align="center">Loading...</Typography>
        ) : camoData ? (
          <Box component="table" sx={{ width: '100%', background: 'rgba(29, 29, 29, 0.9)', borderRadius: '10px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.7)' }}>
            <thead>
              <tr>
                <th>Category</th>
                <th>Gold</th>
                <th>Diamond</th>
                <th>Dark Spine</th>
                <th>Dark Matter</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(camoData).map((category) => (
                <React.Fragment key={category}>
                  <tr className="category-row" style={{ background: '#1a1a1a' }}>
                    <td colSpan="5" style={{ fontWeight: 'bold', color: '#e63946', padding: '10px', textAlign: 'center' }}>{category}</td>
                  </tr>
                  {Object.keys(camoData[category]).map((gun) => (
                    <tr key={gun} style={{ background: '#252525' }}>
                      <td style={{ color: '#d4d4d4', padding: '8px' }}>{gun}</td>
                      {["Gold", "Diamond", "Dark Spine", "Dark Matter"].map((camoType) => (
                        <td key={camoType} style={{ textAlign: 'center' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={camoData[category][gun][camoType]}
                                onChange={handleCheckboxChange(category, gun, camoType)}
                                sx={{
                                  color: '#e63946',
                                  '&.Mui-checked': { color: '#e63946' },
                                }}
                              />
                            }
                            label=""
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Box>
        ) : (
          <Typography align="center" sx={{ color: '#d4d4d4' }}>No camo data available.</Typography>
        )}
      </Container>
    </Box>
  );
}

export default CamoTracker;