import React, { useState } from 'react';
import { Button, Container, TextField, Typography, Box } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const userId = userCredential.user.uid; // Get the user ID from the authenticated user
        navigate(`/dashboard/${userId}`);
      })
      .catch((error) => {
        console.error('Login error: ', error.message);
        setError('Invalid login credentials. Please try again.');
      });
  };  

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #2c3e50 30%, #bdc3c7 100%)', // Matching background
      }}
    >
      <Container
        sx={{
          background: 'rgba(0, 0, 0, 0.75)', // Semi-transparent dark background for the form
          padding: 4,
          borderRadius: '10px',
          color: 'white',
          maxWidth: '400px',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Login to StreamSentinel
        </Typography>

        {error && (
          <Typography color="error" align="center" gutterBottom>
            {error}
          </Typography>
        )}

        <form onSubmit={handleLogin}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            InputProps={{
              sx: {
                backgroundColor: 'white', // White background for input fields
                borderRadius: 1,
              },
            }}
          />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            type="password"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              sx: {
                backgroundColor: 'white', // White background for input fields
                borderRadius: 1,
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </form>

        <Button
          color="secondary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => navigate('/signup')}
        >
          Don't have an account? Sign Up
        </Button>
      </Container>
    </Box>
  );
}

export default Login;
