import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, List, ListItem, ListItemText, Drawer } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import logo from '../assets/images/streamsentinel-logo.png'; 

function TermsOfService() {
  const [userId, setUserId] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const drawerWidth = 240;

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0a0a0a 20%, #121212 60%, #232323 100%)',
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
            color: 'white',
            borderRight: '1px solid rgba(230, 57, 70, 0.8)', // subtle red accent
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        {/* Navigation Links */}
        <List>
          <ListItem button onClick={() => navigate('/')}>
            <ListItemText primary="Home Page" />
          </ListItem>
          <ListItem button onClick={() => navigate('/subscribe')}>
            <ListItemText primary="Subscribe" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/dashboard/${userId}`)}>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigate('/aboutme')}>
            <ListItemText primary="About Me" />
          </ListItem>
          <ListItem button onClick={() => navigate('/contact')}>
            <ListItemText primary="Contact" />
          </ListItem>
          <ListItem button onClick={() => navigate('/releases')}>
            <ListItemText primary="Release Updates" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/overlay/${userId}`)}>
            <ListItemText primary="Camo Overlay" />
          </ListItem>
      </List>

        {/* Bottom Section for Login and Signup */}
        <Box sx={{ paddingBottom: 2 }}>
          <List>
            <ListItem button onClick={() => navigate('/login')}>
              <ListItemText primary="Login" />
            </ListItem>
            <ListItem button onClick={() => navigate('/signup')}>
              <ListItemText primary="Create Account" />
            </ListItem>
          </List>
          <Box sx={{ textAlign: 'center', padding: 1 }}>
          <img
            src={logo}
            alt="StreamSentinel Logo"
            style={{ maxWidth: '70%', marginBottom: '10px', height: 'auto' }}
          />
          </Box>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Container
          sx={{
            background: 'rgba(0, 0, 0, 0.75)', // Semi-transparent background for readability
            padding: 4,
            borderRadius: '10px',
            color: 'white',
          }}
        >
          <Typography variant="h3" align="center" gutterBottom>
            Terms of Service
          </Typography>

          <Typography variant="h6" gutterBottom>
            Last Updated: October 9th, 2024
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By downloading, installing, or using StreamSentinel (the "Application"), you agree to these Terms of Service (the "Terms"). If you do not agree to these Terms, do not use the Application.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Description of Service
          </Typography>
          <Typography paragraph>
            StreamSentinel is a monitoring tool designed to track system metrics, stream performance, and provide real-time alerts through integration with services like OBS Studio, Twitch, and Discord. Based on your subscription tier (Free, Premium, or Professional), you will have access to different features.
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Subscription and Payment
          </Typography>
          <Typography paragraph>
            <strong>Free Tier:</strong> Limited features available without charge.<br />
            <strong>Premium & Professional Tiers:</strong> Subscription-based services managed via Stripe. Upon successful payment, a License Key will be issued.<br />
            <strong>Cancellation:</strong> You may cancel your subscription at any time. Cancellations will take effect at the end of the current billing cycle, and the License Key will be revoked.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. User Accounts and License Key
          </Typography>
          <Typography paragraph>
            You are required to enter a valid License Key upon first use for Premium and Professional tiers. Misuse or sharing of License Keys may result in termination of access.
          </Typography>

          {/* Other sections go here following the same format */}
          
        </Container>
      </Box>
    </Box>
  );
}

export default TermsOfService;
