import { Box, Typography, Container, Link, List, ListItem, ListItemText, Drawer, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/streamsentinel-logo.png'; 
import { getAuth } from 'firebase/auth';
import React, { useState, useEffect } from 'react';
import '../App.css'; // Import the CSS file for animations

function Contact() {
  const [userId, setUserId] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const drawerWidth = 240;

  const developerEmail = "cody@streamsentinel.org";
  const discordInviteLink = "https://discord.gg/UFKYjYHGcw";

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0a0a0a 20%, #121212 60%, #232323 100%)',
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
            color: 'white',
            borderRight: '1px solid rgba(230, 57, 70, 0.8)', // subtle red accent
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem button onClick={() => navigate('/')}>
            <ListItemText primary="Home Page" />
          </ListItem>
          <ListItem button onClick={() => navigate('/subscribe')}>
            <ListItemText primary="Subscribe" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/dashboard/${userId}`)}>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigate('/aboutme')}>
            <ListItemText primary="About Me" />
          </ListItem>
          <ListItem button onClick={() => navigate('/contact')}>
            <ListItemText primary="Contact" />
          </ListItem>
          <ListItem button onClick={() => navigate('/releases')}>
            <ListItemText primary="Release Updates" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/overlay/${userId}`)}>
            <ListItemText primary="Camo Overlay" />
          </ListItem>
        </List>
        <Box sx={{ position: 'absolute', bottom: 0, padding: 2, textAlign: 'center' }}>
          <img
            src={logo}
            alt="StreamSentinel Logo"
            style={{
              maxWidth: '70%',
              marginBottom: '10px',
              filter: 'drop-shadow(1px 1px 3px rgba(255, 255, 255, 0.8))',
            }}
          />
          <List>
            <ListItem button onClick={() => navigate('/login')}>
              <ListItemText primary="Login" sx={{ color: '#e63946' }} />
            </ListItem>
            <ListItem button onClick={() => navigate('/signup')}>
              <ListItemText primary="Create Account" sx={{ color: '#e63946' }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Box sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Container
          sx={{
            background: 'rgba(0, 0, 0, 0.75)',
            padding: 4,
            borderRadius: '10px',
            color: 'white',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.7)',
          }}
        >
          <Typography variant="h3" align="center" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="h6" gutterBottom>
            Developer Email
          </Typography>
          <Typography paragraph>
            For support, inquiries, or collaboration opportunities, contact us at:{" "}
            <Link href={`mailto:${developerEmail}`} color="secondary">{developerEmail}</Link>
          </Typography>
          <Typography variant="h6" gutterBottom>
            Join Our Community on Discord
          </Typography>
          <Typography paragraph>
            Be part of the StreamSentinel community! Our Discord server provides real-time support, feature updates,
            and discussions. It’s a great space to connect with other streamers and provide feedback. Click below to join:
          </Typography>
          <Box sx={{ textAlign: 'center', marginTop: 2 }}>
            <Button variant="contained" color="primary" href={discordInviteLink} target="_blank" sx={{
              backgroundColor: '#e63946',
              borderRadius: '50px',
              padding: '10px 30px',
              fontWeight: 'bold',
              boxShadow: '0px 4px 12px rgba(230, 57, 70, 0.6)',
              '&:hover': { backgroundColor: '#c72533' }
            }}>
              Join Discord Server
            </Button>
          </Box>
          <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Community Resources and Social Media
          </Typography>
          <Typography paragraph>
            Stay connected with us through social media for the latest news, tutorials, and community highlights.
            You can follow us on Twitter, YouTube, and Twitch for live events and updates.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Future Development and Beta Testing
          </Typography>
          <Typography paragraph>
            Interested in trying new features before release? Join our beta testing program! As a tester, you’ll gain early
            access to upcoming tools and help shape the future of StreamSentinel with your feedback.
          </Typography>
        </Container>
        <Box sx={{ textAlign: 'center', padding: 2 }}>
          <Button color="inherit" onClick={() => navigate('/terms')}>Terms of Service</Button>
          <Button color="inherit" onClick={() => navigate('/privacy')}>Privacy Policy</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Contact;