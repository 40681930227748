import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyA6C54X0rSsjEaNDnJH6C5v6OmBye26bnk",
  authDomain: "monitorapp-7e92a.firebaseapp.com",
  databaseURL: "https://monitorapp-7e92a-default-rtdb.firebaseio.com",
  projectId: "monitorapp-7e92a",
  storageBucket: "monitorapp-7e92a.appspot.com",
  messagingSenderId: "455283137791",
  appId: "1:455283137791:web:2c78bca027ac61262a80ca",
  measurementId: "G-KCW9WMMSTQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and Realtime Database
export const analytics = getAnalytics(app);
export const db = getDatabase(app);

// Re-export logEvent specifically for use with analytics
export { logEvent };