// camoOverlay.js
import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { useParams } from 'react-router-dom';
import './camoOverlay.css';
import { db } from '../firebase';

const camoTiers = [
  { name: 'Gold', max: 33, theme: 'gold-theme', progressKey: 'gold_count' },
  { name: 'Diamond', max: 33, theme: 'diamond-theme', progressKey: 'diamond_count' },
  { name: 'Dark Spine', max: 33, theme: 'darkspine-theme', progressKey: 'dark_spine_count' },
  { name: 'Dark Matter', max: 33, theme: 'darkmatter-theme', progressKey: 'dark_matter_count' },
];

function CamoOverlay() {
  const { userId } = useParams();
  const [progress, setProgress] = useState({
    gold_count: 0,
    diamond_count: 0,
    dark_spine_count: 0,
    dark_matter_count: 0,
  });

  useEffect(() => {
    if (userId) {
      const progressRef = ref(db, `users/${userId}/progress`);
      const unsubscribeProgress = onValue(progressRef, (snapshot) => {
        const data = snapshot.val() || {};
        setProgress({
          gold_count: data.gold_count || 0,
          diamond_count: data.diamond_count || 0,
          dark_spine_count: data.dark_spine_count || 0,
          dark_matter_count: data.dark_matter_count || 0,
        });
      });

      return () => {
        unsubscribeProgress();
      };
    }
  }, [userId]);

  return (
    <div className="camo-container">
      {camoTiers.map((tier) => (
        <div key={tier.name} className={`camo-tier ${tier.theme}`}>
          <div className="overlay">
            <span className="camo-title">{tier.name}</span>
            <span className="progress-text">
              {progress[tier.progressKey]}/{tier.max}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CamoOverlay;