import { Box, Typography, Container, List, ListItem, ListItemText, Drawer } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/streamsentinel-logo.png';
import { getAuth } from 'firebase/auth';
import React, { useState, useEffect } from 'react';

function AboutMe() {
  const [userId, setUserId] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  const drawerWidth = 240;

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0a0a0a 20%, #121212 60%, #232323 100%)',
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
            color: 'white',
            borderRight: '1px solid rgba(230, 57, 70, 0.8)', // subtle red accent
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem button onClick={() => navigate('/')}>
            <ListItemText primary="Home Page" />
          </ListItem>
          <ListItem button onClick={() => navigate('/subscribe')}>
            <ListItemText primary="Subscribe" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/dashboard/${userId}`)}>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigate('/aboutme')}>
            <ListItemText primary="About Me" />
          </ListItem>
          <ListItem button onClick={() => navigate('/contact')}>
            <ListItemText primary="Contact" />
          </ListItem>
          <ListItem button onClick={() => navigate('/releases')}>
            <ListItemText primary="Release Updates" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/overlay/${userId}`)}>
            <ListItemText primary="Camo Overlay" />
          </ListItem>
        </List>
        <Box sx={{ position: 'absolute', bottom: 0, padding: 2, textAlign: 'center' }}>
          <img
            src={logo}
            alt="StreamSentinel Logo"
            style={{
              maxWidth: '70%',
              marginBottom: '10px',
              filter: 'drop-shadow(1px 1px 3px rgba(255, 255, 255, 0.8))',
            }}
          />
          <List>
            <ListItem button onClick={() => navigate('/login')}>
              <ListItemText primary="Login" sx={{ color: '#e63946' }} />
            </ListItem>
            <ListItem button onClick={() => navigate('/signup')}>
              <ListItemText primary="Create Account" sx={{ color: '#e63946' }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container
          sx={{
            background: 'rgba(29, 29, 29, 0.9)', // Semi-transparent for depth effect
            padding: 4,
            borderRadius: '12px',
            maxWidth: '800px',
            color: 'white',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.7)',
          }}
        >
          <Typography variant="h3" align="center" gutterBottom sx={{ color: '#e63946', fontWeight: 'bold', mb: 4 }}>
            About Me
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ color: '#e63946', fontWeight: 'bold' }}>
            Background
          </Typography>
          <Typography paragraph sx={{ color: '#d4d4d4' }}>
            I am Cody, a software developer with a strong background in Network Engineering and Cyber Security. Over the years, I have
            specialized in developing solutions that enhance performance, reliability, and security, primarily in the private sector. 
            My experience has shaped my skills in optimizing both hardware and software for peak performance, laying the foundation 
            for innovative projects like StreamSentinel.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ color: '#e63946', fontWeight: 'bold' }}>
            The Inspiration Behind StreamSentinel
          </Typography>
          <Typography paragraph sx={{ color: '#d4d4d4' }}>
            StreamSentinel began as a personal project aimed at solving real-time streaming challenges I encountered firsthand. 
            As a developer and avid streamer, I faced the ongoing difficulty of monitoring system resources, balancing performance metrics, 
            and maintaining a consistent quality output during live sessions. I wanted a tool that not only provided insights but also empowered 
            streamers to take action immediately, ensuring smooth and professional streams regardless of their technical background.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ color: '#e63946', fontWeight: 'bold' }}>
            Mission and Vision
          </Typography>
          <Typography paragraph sx={{ color: '#d4d4d4' }}>
            StreamSentinel’s mission is to deliver professional-grade monitoring tools that simplify performance management for streamers 
            and gamers alike. My vision for StreamSentinel is to build an all-encompassing platform that brings critical insights, automated 
            alerts, and actionable solutions directly to streamers, helping them achieve a seamless, high-quality streaming experience.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ color: '#e63946', fontWeight: 'bold' }}>
            Core Values
          </Typography>
          <Typography paragraph sx={{ color: '#d4d4d4' }}>
            StreamSentinel is built on principles of innovation, accessibility, and user empowerment. I am committed to delivering an 
            intuitive interface, backed by robust technical tools, to ensure that users of any skill level can monitor and optimize 
            their setups without frustration. By prioritizing user feedback, I strive to create a tool that evolves with the needs 
            of the streaming community.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ color: '#e63946', fontWeight: 'bold' }}>
            Future Plans for StreamSentinel
          </Typography>
          <Typography paragraph sx={{ color: '#d4d4d4' }}>
            The current vision for StreamSentinel extends beyond monitoring: I am working towards evolving it into a comprehensive 
            streaming software that integrates seamlessly with your setup. This future version will house all essential streaming tools 
            in one place—from system monitoring and alerts to scene management and real-time optimization suggestions. With AI 
            assistance for setting up and troubleshooting, StreamSentinel aims to redefine what streamers can expect from a single, 
            user-friendly platform.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}

export default AboutMe;
