import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Grid, Box, Drawer, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import logo from '../assets/images/streamsentinel-logo.png';

function Dashboard() {
  // Extract the userId from the URL
  const { userId } = useParams(); 

  const APP_VERSION = '1.105';
  const navigate = useNavigate();
  const auth = getAuth();
  const firestore = getFirestore();

  // Initialize Firebase Functions
  const functions = getFunctions();
  const cancelSubscription = httpsCallable(functions, 'cancelSubscription');

  // State to hold customer data
  const [subscriptionStatus, setSubscriptionStatus] = useState('Inactive');
  const [subscriptionTier, setSubscriptionTier] = useState('Free');
  const [licenseKey, setLicenseKey] = useState('No License');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cancelMessage, setCancelMessage] = useState('');
  const [uniqueURL, setUniqueURL] = useState('');

  // Camo Progress States
  const [currentTierIndex, setCurrentTierIndex] = useState(0);
  const [unlockedGuns, setUnlockedGuns] = useState(0);

  // Camo tiers configuration
  const camoTiers = [
    { name: 'Gold', max: 33 },
    { name: 'Diamond', max: 33 },
    { name: 'Dark Spine', max: 33 },
    { name: 'Dark Matter', max: 33 },
  ];

  // Real download link and discord link
  const downloadableFileLink = "https://firebasestorage.googleapis.com/v0/b/monitorapp-7e92a.appspot.com/o/Stream%20Sentinel%201.105.zip?alt=media&token=1853bb58-f39f-4e20-8abc-fce9779053ee";
  const discordBotLink = "https://discord.com/oauth2/authorize?client_id=1292188459687219210";

  // Fetch customer data from Firestore in real-time
  useEffect(() => {
    if (!userId) {
      setLoading(false);
      setError('User ID is missing.');
      return;
    }

    setLoading(true);

    const customerDocRef = doc(firestore, 'customers', userId);
    
    // Subscribe to Firestore document updates in real-time
    const unsubscribe = onSnapshot(customerDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const customerData = docSnapshot.data();
        
        setSubscriptionStatus(customerData.subscriptionStatus || 'Inactive');
        setSubscriptionTier(customerData.subscriptionTier || 'Free');
        setLicenseKey(customerData.licenseKey || 'No License');

        setUnlockedGuns(customerData.unlockedGuns || 0);
        setCurrentTierIndex(customerData.currentTierIndex || 0);

        const baseURL = window.location.origin;
        const overlayURL = `${baseURL}/overlay/${userId}`;
        setUniqueURL(overlayURL);
      } else {
        setError('Customer data not found.');
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, [firestore, userId]);  

  const handleLogout = () => {
    auth.signOut().then(() => navigate('/login'));
  };

  const handleCancelSubscription = async () => {
    try {
      const result = await cancelSubscription();
      setCancelMessage(result.data.message);
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      setCancelMessage('Failed to cancel subscription. Please try again later.');
    }
  };

  const incrementProgress = async () => {
    if (!userId) return;

    let newUnlockedGuns = unlockedGuns;
    let newCurrentTierIndex = currentTierIndex;

    if (newUnlockedGuns < camoTiers[newCurrentTierIndex].max) {
      newUnlockedGuns += 1;
    } else if (newCurrentTierIndex < camoTiers.length - 1) {
      newUnlockedGuns = 1;
      newCurrentTierIndex += 1;
    } else {
      return;
    }

    setUnlockedGuns(newUnlockedGuns);
    setCurrentTierIndex(newCurrentTierIndex);

    try {
      const userDocRef = doc(firestore, 'customers', userId);
      await updateDoc(userDocRef, {
        unlockedGuns: newUnlockedGuns,
        currentTierIndex: newCurrentTierIndex,
      });
    } catch (error) {
      console.error('Error updating camo progress:', error);
    }
  };

  const forceNextTier = async () => {
    if (!userId) return;

    let newCurrentTierIndex = currentTierIndex;

    if (newCurrentTierIndex < camoTiers.length - 1) {
      newCurrentTierIndex += 1;
      setCurrentTierIndex(newCurrentTierIndex);

      try {
        const userDocRef = doc(firestore, 'customers', userId);
        await updateDoc(userDocRef, {
          currentTierIndex: newCurrentTierIndex,
          unlockedGuns: 0, // Reset unlocked guns for the new tier
        });
      } catch (error) {
        console.error('Error forcing next tier:', error);
      }
    }
  };

  const resetProgress = async () => {
    if (!userId) return;

    setUnlockedGuns(0);
    setCurrentTierIndex(0);

    try {
      const userDocRef = doc(firestore, 'customers', userId);
      await updateDoc(userDocRef, {
        unlockedGuns: 0,
        currentTierIndex: 0,
      });
    } catch (error) {
      console.error('Error resetting camo progress:', error);
    }
  };

  const drawerWidth = 240;

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', color: 'red' }}>
        <Typography variant="h6">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0a0a0a 20%, #121212 60%, #232323 100%)',
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
            color: 'white',
            borderRight: '1px solid rgba(230, 57, 70, 0.8)', // subtle red accent
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem button onClick={() => navigate('/')}>
            <ListItemText primary="Home Page" />
          </ListItem>
          <ListItem button onClick={() => navigate('/subscribe')}>
            <ListItemText primary="Subscribe" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/dashboard/${userId}`)}>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigate('/aboutme')}>
            <ListItemText primary="About Me" />
          </ListItem>
          <ListItem button onClick={() => navigate('/contact')}>
            <ListItemText primary="Contact" />
          </ListItem>
          <ListItem button onClick={() => navigate('/releases')}>
            <ListItemText primary="Release Updates" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/overlay/${userId}`)}>
            <ListItemText primary="Camo Overlay" />
          </ListItem>
        </List>
        <Box sx={{ position: 'absolute', bottom: 0, padding: 2, textAlign: 'center' }}>
          <img
            src={logo}
            alt="StreamSentinel Logo"
            style={{
              maxWidth: '70%',
              marginBottom: '10px',
              filter: 'drop-shadow(1px 1px 3px rgba(255, 255, 255, 0.8))',
            }}
          />
          <List>
            <ListItem button onClick={() => navigate('/login')}>
              <ListItemText primary="Login" sx={{ color: '#e63946' }} />
            </ListItem>
            <ListItem button onClick={() => navigate('/signup')}>
              <ListItemText primary="Create Account" sx={{ color: '#e63946' }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Box sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Container
          sx={{
            background: `rgba(0, 0, 0, 0.6)`,
            padding: 4,
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
          }}
        >
          <Typography variant="h3" align="center" gutterBottom>
            Welcome, {userId || 'User'}
          </Typography>

          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Typography variant="h6">Subscription Status: {subscriptionStatus}</Typography>
            <Typography variant="h6">Subscription Tier: {subscriptionTier}</Typography>
            <Typography variant="h6">License Key: {licenseKey}</Typography>
          </Box>

          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Typography variant="h6">Current Tier: {camoTiers[currentTierIndex].name}</Typography>
            <Typography variant="h6">Unlocked Guns: {unlockedGuns}/{camoTiers[currentTierIndex].max}</Typography>
          </Box>

          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Button variant="contained" color="primary" onClick={incrementProgress} sx={{ marginRight: 2 }}>
              +1 Progress
            </Button>
            <Button variant="contained" color="secondary" onClick={resetProgress}>
              Reset Progress
            </Button>
          </Box>

          {/* Force Next Tier Button */}
          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Button variant="contained" color="warning" onClick={forceNextTier}>
              Force Next Tier
            </Button>
          </Box>

          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Typography variant="h6">Your Unique Overlay URL:</Typography>
            <input type="text" value={uniqueURL} readOnly style={{ width: '100%', textAlign: 'center' }} />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigator.clipboard.writeText(uniqueURL)}
              sx={{ marginTop: 2 }}
            >
              COPY URL
            </Button>
          </Box>

          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate(`/camoTracker/${userId}`)}
            >
              Go to Camo Tracker
            </Button>
          </Box>

          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Button variant="contained" color="secondary" onClick={handleCancelSubscription}>
              Cancel Subscription
            </Button>
            {cancelMessage && (
              <Typography variant="body2" color="white" sx={{ mt: 2 }}>
                {cancelMessage}
              </Typography>
            )}
          </Box>

          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Typography variant="h6">
              <a href={downloadableFileLink} style={{ color: '#00c853' }}>Download StreamSentinel</a>
            </Typography>
            {subscriptionTier === "Professional" && (
              <Typography variant="h6">
                <a href={discordBotLink} style={{ color: '#00c853' }}>Install Discord Bot</a>
              </Typography>
            )}
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" color="secondary" onClick={handleLogout}>
                Logout
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Box sx={{ textAlign: 'center', padding: 2 }}>
          <Button color="inherit" onClick={() => navigate('/terms')}>Terms of Service</Button>
          <Button color="inherit" onClick={() => navigate('/privacy')}>Privacy Policy</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;