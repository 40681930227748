import { Box, Typography, Container, List, ListItem, ListItemText, Drawer, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/streamsentinel-logo.png';
import { getAuth } from 'firebase/auth';
import React, { useState, useEffect } from 'react';
import '../App.css'; // Import the CSS file for animations

function Releases() {
  const [userId, setUserId] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const drawerWidth = 240;

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0a0a0a 20%, #121212 60%, #232323 100%)',
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
            color: 'white',
            borderRight: '1px solid rgba(230, 57, 70, 0.8)', // subtle red accent
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem button onClick={() => navigate('/')}>
            <ListItemText primary="Home Page" />
          </ListItem>
          <ListItem button onClick={() => navigate('/subscribe')}>
            <ListItemText primary="Subscribe" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/dashboard/${userId}`)}>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigate('/aboutme')}>
            <ListItemText primary="About Me" />
          </ListItem>
          <ListItem button onClick={() => navigate('/contact')}>
            <ListItemText primary="Contact" />
          </ListItem>
          <ListItem button onClick={() => navigate('/releases')}>
            <ListItemText primary="Release Updates" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/overlay/${userId}`)}>
            <ListItemText primary="Camo Overlay" />
          </ListItem>
        </List>
        <Box sx={{ position: 'absolute', bottom: 0, padding: 2, textAlign: 'center' }}>
          <img
            src={logo}
            alt="StreamSentinel Logo"
            style={{
              maxWidth: '70%',
              marginBottom: '10px',
              filter: 'drop-shadow(1px 1px 3px rgba(255, 255, 255, 0.8))',
            }}
          />
          <List>
            <ListItem button onClick={() => navigate('/login')}>
              <ListItemText primary="Login" sx={{ color: '#e63946' }} />
            </ListItem>
            <ListItem button onClick={() => navigate('/signup')}>
              <ListItemText primary="Create Account" sx={{ color: '#e63946' }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Box sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Container
          sx={{
            background: 'rgba(0, 0, 0, 0.75)',
            padding: 4,
            borderRadius: '10px',
            color: 'white',
          }}
        >
          <Typography variant="h3" align="center" gutterBottom>
            Release Updates
          </Typography>

          {/* Release 1.0 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h4" gutterBottom>
              Release 1.0
            </Typography>
            <Typography variant="body1" paragraph>
              Version 1.0 marks the foundational release of StreamSentinel, providing essential real-time monitoring capabilities 
              to ensure streamers can deliver smooth, high-quality broadcasts.
            </Typography>
            <Typography variant="h6">Key Metrics in 1.0:</Typography>
            <Typography component="ul" sx={{ marginLeft: 4 }}>
              <li><strong>CPU Monitoring:</strong> Monitors temperature and usage to prevent overheating and performance throttling.</li>
              <li><strong>GPU Monitoring:</strong> Real-time GPU temperature and usage tracking to optimize graphics handling during streaming.</li>
              <li><strong>RAM Usage:</strong> Displays memory usage to avoid crashes from high consumption.</li>
              <li><strong>Latency and Packet Loss:</strong> Tracks latency and detects packet loss for uninterrupted network performance.</li>
              <li><strong>OBS Frame Monitoring:</strong> Alerts for dropped and skipped frames to optimize encoding settings.</li>
              <li><strong>Network Speed Test:</strong> Checks upload/download speed, ensuring stable streaming bandwidth.</li>
              <li><strong>Custom Discord Alerts:</strong> Alerts streamers in real-time to critical issues directly in Discord.</li>
              <li><strong>Discord Bot Integration:</strong> Customizable bot provides live updates and alert configurations in Discord.</li>
            </Typography>
          </Box>

          {/* Release 1.1 */}
          <Box>
            <Typography variant="h4" gutterBottom>
              Upcoming Release 1.1
            </Typography>
            <Typography variant="body1" paragraph>
              Version 1.1 is set to revolutionize StreamSentinel by integrating with OBS and incorporating an all-in-one streaming solution. 
              This release combines monitoring, scene management, and real-time alerts to deliver a seamless streaming experience.
            </Typography>
            <Typography variant="h6">Highlights of 1.1:</Typography>
            <Typography component="ul" sx={{ marginLeft: 4 }}>
              <li><strong>OBS + StreamSentinel Integration:</strong> Deep integration with OBS enables direct adjustments to streaming configurations based on real-time data, reducing latency and optimizing quality with minimal setup.</li>
              <li><strong>All-in-One Streaming Platform:</strong> This unified platform brings together all essential tools for stream management, performance monitoring, and audience engagement in a single interface.</li>
              <li><strong>User-Friendly Dashboard:</strong> Redesigned with simplicity in mind, making it accessible to streamers of all experience levels.</li>
              <li><strong>AI Assistance:</strong> Embedded AI-powered support for troubleshooting and optimization recommendations, guiding users through setup and suggesting improvements based on metrics.</li>
            </Typography>
            <Typography variant="body1" paragraph>
              Release 1.1 is crafted to empower streamers, offering intuitive controls and advanced support to deliver high-quality streams without complexity.
            </Typography>
          </Box>
        </Container>

        {/* Technical Structure Section */}
        <Container sx={{ textAlign: 'center', marginTop: '50px', backgroundColor: '#1a1a1a', padding: '20px', borderRadius: '12px' }}>
          <Typography variant="h4" sx={{ color: '#e63946', fontWeight: 'bold', marginBottom: 3 }}>Technical Directory Structure</Typography>
          <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto' }}>
          <pre style={{ textAlign: 'left', color: '#e63946' }}>
            {`
              StreamSentinel/
              ├── app_logic/               # Main application logic
              │   └── main.py              # Entry point of the application
              ├── config/                  # Configuration files (e.g., Firebase)
              │   └── service_account.json  # Firebase Admin SDK credentials
              ├── discord_bot/             # Discord bot-related code
              │   └── discord_alerts.py    # Alert system for Discord
              ├── firebase/                # Firebase interaction logic
              │   └── firebase_manager.py  # Manages Firebase operations
              ├── metrics/                 # System metrics collection
              │   └── system_metrics.py    # Collects CPU, GPU, RAM, and network metrics
              ├── obs_ws_client/           # OBS WebSocket client for stream monitoring
              ├── optimize_obs/            # OBS optimization logic
              ├── ui/                      # User interface for metrics dashboard
              │   └── tier_selection.py    # UI for selecting tiers and metrics
            `}
          </pre>
          </Typography>
          </Container>

          {/* StreamSentinel Information Section */}
          <Container sx={{ textAlign: 'center', marginTop: '50px', backgroundColor: '#1a1a1a', padding: '20px', borderRadius: '12px' }}>
            <Typography variant="h4" sx={{ color: '#e63946', fontWeight: 'bold', marginBottom: 3 }}>StreamSentinel</Typography>
            <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto' }}>
             StreamSentinel is a monitoring tool designed to help streamers keep track of their system performance while streaming. It offers real-time monitoring of critical metrics such as CPU/GPU usage, temperature, RAM usage, and stream status (via OBS). For premium and professional tiers, it provides integration with Discord for alerting, as well as OBS optimization features.
            </Typography>

            <Typography variant="h5" sx={{ color: '#e63946', marginTop: 4, fontWeight: 'bold' }}>Table of Contents</Typography>
            <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
              <ul>
                <li>Features</li>
                <li>Installation</li>
                <li>Configuration</li>
                <li>Usage</li>
                <li>Directory Structure</li>
                <li>License Key System</li>
                <li>Development Setup</li>
                <li>Firebase Configuration</li>
                <li>Discord Bot Integration</li>
                <li>Testing</li>
                <li>Contributing</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ color: '#e63946', marginTop: 4, fontWeight: 'bold' }}>Features</Typography>
            <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
              <ul>
                <li>Real-Time Monitoring: Monitors CPU, GPU, RAM usage, and network metrics like latency and packet loss.</li>
                <li>Discord Alerts: Sends real-time alerts to your Discord channel when system metrics exceed predefined thresholds.</li>
                <li>OBS Studio Integration: Tracks your OBS streaming status and offers stream optimizations.</li>
                <li>Customizable Thresholds: Set thresholds for metrics like CPU usage, temperature, packet loss, etc.</li>
                <li>Multi-tier Subscription Model:
                  <ul>
                    <li>Free Tier: Basic system monitoring (CPU usage, RAM usage, and stream status).</li>
                    <li>Premium Tier: Includes additional metrics like CPU/GPU temperature and GPU usage.</li>
                    <li>Professional Tier: Includes Discord integration, full metric suite, and OBS optimizations.</li>
                  </ul>
                </li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ color: '#e63946', marginTop: 4, fontWeight: 'bold' }}>Installation</Typography>
            <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
              <strong>Prerequisites</strong><br />
              Python 3.9+<br />
              pip (Python package manager)<br />
              Firebase Project (for Firestore and authentication)<br />
              Discord Bot (for alerting)
            </Typography>
            <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
              <strong>Virtual Environment Setup</strong><br />
              It’s recommended to use a virtual environment to isolate your Python packages. To create and activate a virtual environment, run the following commands.
            </Typography>
          </Container>

        <Box sx={{ textAlign: 'center', padding: 2 }}>
          <Button color="inherit" onClick={() => navigate('/terms')}>Terms of Service</Button>
          <Button color="inherit" onClick={() => navigate('/privacy')}>Privacy Policy</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Releases;