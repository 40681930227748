// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './firebaseConfig';  // Import firebase config to initialize the app
import App from './App';     // Import the App component where routes are defined
import './index.css';
import './Storefront.css';   // Ensure CSS for the Storefront page is imported

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />  {/* Render the App component, which contains all the routing logic */}
  </React.StrictMode>
);
