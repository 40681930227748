import React, { useState } from 'react';
import { Button, Container, TextField, Typography, Box } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { getDatabase, ref, set } from 'firebase/database'; // Realtime Database imports

function Signup() {
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore(); // Initialize Firestore for customer data
  const dbRealtime = getDatabase(); // Initialize Realtime Database for camo data
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  // Default camo data structure
  const defaultCamos = {
    "Assault Rifle": {
      "XM4": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "AK-74": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "AMES 85": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "GPR 91": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "MODEL L": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "GOBLIN MK 2": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "AS VAL": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
    },
    "SMG": {
      "C9": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "KSV": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "TANTO .22": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "PP-919": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "JACKAL PDW": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "KOMPAKT 92": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
    },
    "Shotgun": {
      "MARINE SP": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "ASG-89": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
    },
    "Marksman Rifle": {
      "SWAT 5.56": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "Tsarkov 7.62": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "AEK-973": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "DM-10": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
    },
    "Pistol": {
      "9MM PM": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "Grekhova": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "GS45": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "Stryder .22": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
    },
    "Launcher": {
      "CIGMA 2B": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "HE-1": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
    },
    "Sniper": {
      "LW3A1 Frostline": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "SVD": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "LR 7.62": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
    },
    "Melee": {
      "Knife": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "Baseball Bat": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
    },
    "LMG": {
      "PU-21": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "XMG": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false },
      "GPMG-7": { "Gold": false, "Diamond": false, "Dark Spine": false, "Dark Matter": false }
    }
  };

  // Function to create customer data in Firestore and camo data in Realtime Database
  const createCustomerData = async (uid, email) => {
    try {
      // Add basic customer data to Firestore 'customers' collection
      await setDoc(doc(db, 'customers', uid), {
        email: email,
        createdAt: serverTimestamp(), // Add server timestamp for record creation
        subscriptionStatus: 'none',   // Default subscription status
        userId: uid                   // Store user ID for reference
      });

      // Add default camo data structure to Realtime Database
      await set(ref(dbRealtime, `users/${uid}/camos`), defaultCamos);

      console.log('Customer data added to Firestore, and camo data added to Realtime Database');
    } catch (err) {
      console.error('Error creating customer/camo data:', err);
    }
  };

  // Handle sign up process
  const handleSignup = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const { user } = userCredential;
        // Call the function to create the customer in Firestore and camo data in Realtime DB
        createCustomerData(user.uid, user.email);

        // Navigate to dashboard after successful signup
        navigate('/dashboard');
      })
      .catch((error) => {
        console.error('Signup error: ', error.message);
        setError('Failed to create an account. Please try again.');
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #2c3e50 30%, #bdc3c7 100%)', // Matching background
      }}
    >
      <Container
        sx={{
          background: 'rgba(0, 0, 0, 0.75)', // Semi-transparent dark background for the form
          padding: 4,
          borderRadius: '10px',
          color: 'white',
          maxWidth: '400px',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Sign Up for StreamSentinel
        </Typography>

        {error && (
          <Typography color="error" align="center" gutterBottom>
            {error}
          </Typography>
        )}

        <form onSubmit={handleSignup}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            InputProps={{
              sx: {
                backgroundColor: 'white', // White background for input fields
                borderRadius: 1,
              },
            }}
          />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            type="password"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              sx: {
                backgroundColor: 'white', // White background for input fields
                borderRadius: 1,
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Sign Up
          </Button>
        </form>

        <Button
          color="secondary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => navigate('/login')}
        >
          Already have an account? Log In
        </Button>
      </Container>
    </Box>
  );
}

export default Signup;