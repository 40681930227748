import { Box, Typography, Container, List, ListItem, ListItemText, Drawer } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/streamsentinel-logo.png'; // Ensure correct path for the logo
import { getAuth } from 'firebase/auth';
import React, { useState, useEffect } from 'react';


function PrivacyPolicy() {
  const [userId, setUserId] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const drawerWidth = 240;

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0a0a0a 20%, #121212 60%, #232323 100%)',
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
            color: 'white',
            borderRight: '1px solid rgba(230, 57, 70, 0.8)', // subtle red accent
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        {/* Navigation Links */}
        <List>
          <ListItem button onClick={() => navigate('/')}>
            <ListItemText primary="Home Page" />
          </ListItem>
          <ListItem button onClick={() => navigate('/subscribe')}>
            <ListItemText primary="Subscribe" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/dashboard/${userId}`)}>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigate('/aboutme')}>
            <ListItemText primary="About Me" />
          </ListItem>
          <ListItem button onClick={() => navigate('/contact')}>
            <ListItemText primary="Contact" />
          </ListItem>
          <ListItem button onClick={() => navigate('/releases')}>
            <ListItemText primary="Release Updates" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/overlay/${userId}`)}>
            <ListItemText primary="Camo Overlay" />
          </ListItem>
      </List>

        {/* Bottom Section for Login and Signup */}
        <Box sx={{ paddingBottom: 2 }}>
          <List>
            <ListItem button onClick={() => navigate('/login')}>
              <ListItemText primary="Login" />
            </ListItem>
            <ListItem button onClick={() => navigate('/signup')}>
              <ListItemText primary="Create Account" />
            </ListItem>
          </List>
          <Box sx={{ textAlign: 'center', padding: 1 }}>
          <img
            src={logo}
            alt="StreamSentinel Logo"
            style={{ maxWidth: '70%', marginBottom: '10px', height: 'auto' }}
          />
          </Box>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Container
          sx={{
            background: 'rgba(0, 0, 0, 0.75)', // Semi-transparent background for readability
            padding: 4,
            borderRadius: '10px',
            color: 'white',
          }}
        >
          <Typography variant="h3" align="center" gutterBottom>
            Privacy Policy
          </Typography>

          <Typography variant="h6" gutterBottom>
            Last Updated: October 9th, 2024
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography paragraph>
            Personal Information: When you subscribe, we collect your email address, Twitch UserID (for Twitch integration), and License Key.
          </Typography>
          <Typography paragraph>
            Payment Information: Payments are processed through Stripe. We do not store your payment details.
          </Typography>
          <Typography paragraph>
            System Metrics: StreamSentinel collects system performance data (e.g., CPU usage, GPU usage, RAM usage) and streaming metrics (e.g., OBS Studio dropped frames, latency, packet loss).
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography paragraph>
            Subscription Management: To manage your subscription, process payments, and validate your License Key.
          </Typography>
          
          {/* Other sections go here following the same format */}

        </Container>
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
