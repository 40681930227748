// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database'; // Import Realtime Database

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA6C54X0rSsjEaNDnJH6C5v6OmBye26bnk",
  authDomain: "monitorapp-7e92a.firebaseapp.com",
  projectId: "monitorapp-7e92a",
  storageBucket: "monitorapp-7e92a.appspot.com",
  messagingSenderId: "455283137791",
  appId: "1:455283137791:web:2c78bca027ac61262a80ca",
  measurementId: "G-KCW9WMMSTQ",
  databaseURL: "https://monitorapp-7e92a-default-rtdb.firebaseio.com" // Add this line for Realtime Database
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const database = getDatabase(app); // Initialize Realtime Database

// Export the services
export { db, auth, functions, database };