import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import HomePage from './pages/HomePage';
import Login from './pages/login';
import Signup from './pages/signup';
import Dashboard from './pages/dashboard';
import Cancel from './pages/cancel';
import AboutMe from './pages/aboutme';
import Contact from './pages/contact';
import Releases from './pages/releases';
import TermsOfService from './pages/termsofservice';
import PrivacyPolicy from './pages/privacypolicy';
import Subscribe from './pages/Subscribe';
import CamoOverlay from './pages/camoOverlay';
import { getAuth } from 'firebase/auth';
import CamoTracker from './pages/camoTracker';

const theme = createTheme({
  palette: {
    primary: { main: '#e63946' },
    secondary: { main: '#252525' },
    background: {
      default: 'linear-gradient(135deg, #0d0d0d, #1a1a1a, #252525)',
      paper: 'rgba(29, 29, 29, 0.9)',
    },
    text: {
      primary: '#ffffff',
      secondary: '#e63946',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h3: { fontWeight: 700, color: '#ffffff', letterSpacing: '0.05em' },
    h4: { fontWeight: 600, color: '#e63946', letterSpacing: '0.03em' },
    body1: { color: '#d4d4d4', lineHeight: 1.6 },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
          color: 'white',
          borderRight: '1px solid #e63946',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(145deg, #1a1a1a, #252525)',
          color: '#ffffff',
          borderRadius: '12px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.7)',
          transition: 'transform 0.3s, box-shadow 0.3s',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 6px 25px rgba(230, 57, 70, 0.6)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          borderRadius: '50px',
          padding: '10px 30px',
          boxShadow: '0px 4px 12px rgba(230, 57, 70, 0.6)',
          '&:hover': {
            backgroundColor: '#c72533',
          },
        },
      },
    },
  },
});

function AppRoutes() {
  const location = useLocation();
  const [userId, setUserID] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const contentPages = ['/', '/aboutme', '/releases', '/subscribe'];
    if (contentPages.includes(location.pathname)) {
      const script = document.createElement('script');
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1780421594470489";
      script.async = true;
      script.crossOrigin = "anonymous";
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setLoading(false);
      if (user) {
        setUserID(user.uid);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/dashboard/:userId" element={<Dashboard />} />
      <Route path="/cancel" element={<Cancel />} />
      <Route path="/aboutme" element={<AboutMe />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/releases" element={<Releases />} />
      <Route path="/terms" element={<TermsOfService />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/subscribe" element={<Subscribe />} />
      <Route path="/overlay/:userId" element={<CamoOverlay />} />
      <Route path="/camotracker/:userId" element={<CamoTracker />} />
    </Routes>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppRoutes />
      </Router>
    </ThemeProvider>
  );
}

export default App;