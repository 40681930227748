// HomePage.js
import { Box, Typography, Button, Container, Grid, Card, CardContent, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/streamsentinel-logo.png';
import { getAuth } from 'firebase/auth';
import React, { useState, useEffect } from 'react';
import '../App.css'; // Import the CSS file for animations

function HomePage() {
  const [userId, setUserId] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const drawerWidth = 240;

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0a0a0a 20%, #121212 60%, #232323 100%)',
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
            color: 'white',
            borderRight: '1px solid rgba(230, 57, 70, 0.8)', // subtle red accent
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem button onClick={() => navigate('/')}>
            <ListItemText primary="Home Page" />
          </ListItem>
          <ListItem button onClick={() => navigate('/subscribe')}>
            <ListItemText primary="Subscribe" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/dashboard/${userId}`)}>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigate('/aboutme')}>
            <ListItemText primary="About Me" />
          </ListItem>
          <ListItem button onClick={() => navigate('/contact')}>
            <ListItemText primary="Contact" />
          </ListItem>
          <ListItem button onClick={() => navigate('/releases')}>
            <ListItemText primary="Release Updates" />
          </ListItem>
          <ListItem button onClick={() => navigate(`/overlay/${userId}`)}>
            <ListItemText primary="Camo Overlay" />
          </ListItem>
        </List>
        <Box sx={{ position: 'absolute', bottom: 0, padding: 2, textAlign: 'center' }}>
          <img
            src={logo}
            alt="StreamSentinel Logo"
            style={{
              maxWidth: '70%',
              marginBottom: '10px',
              filter: 'drop-shadow(1px 1px 3px rgba(255, 255, 255, 0.8))',
            }}
          />
          <List>
            <ListItem button onClick={() => navigate('/login')}>
              <ListItemText primary="Login" sx={{ color: '#e63946' }} />
            </ListItem>
            <ListItem button onClick={() => navigate('/signup')}>
              <ListItemText primary="Create Account" sx={{ color: '#e63946' }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* Main Content */}
<Box sx={{ flexGrow: 1, color: 'white', padding: 4 }}>

{/* Hero Section */}
<Box sx={{ textAlign: 'center', color: '#ffffff', padding: '50px 0', backgroundColor: '#1a1a1a' }}>
  <img
    src={logo}
    alt="StreamSentinel Logo"
    style={{ maxWidth: '300px', marginBottom: '20px', filter: 'drop-shadow(2px 2px 4px rgba(255, 255, 255, 0.5))' }}
  />
  <Typography
    variant="h4"
    sx={{
      color: '#e63946',
      fontWeight: 'bold',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
    }}
    gutterBottom
  >
    StreamSentinel: The Next Generation in Streaming Performance
  </Typography>
  <Typography variant="h5" sx={{ color: '#d4d4d4', maxWidth: '600px', margin: '0 auto' }} gutterBottom>
    Experience unmatched insights with real-time metrics, AI-assisted optimizations, and a centralized alert system designed for all major platforms.
  </Typography>
  <Button
    variant="contained"
    size="large"
    onClick={() => navigate('/subscribe')}
    sx={{
      marginTop: 3,
      backgroundColor: '#e63946',
      color: 'white',
      borderRadius: '50px',
      padding: '10px 30px',
      fontWeight: 'bold',
      boxShadow: '0px 4px 12px rgba(230, 57, 70, 0.6)',
      '&:hover': { backgroundColor: '#c72533' },
    }}
  >
    Subscribe Now
  </Button>
</Box>

{/* Key Features Section */}
<Container sx={{ textAlign: 'center', marginTop: '50px' }}>
  <Typography variant="h4" sx={{ color: '#e63946', fontWeight: 'bold', marginBottom: 3 }}>Key Features</Typography>
  <Grid container spacing={4}>
    {[
      { title: 'Live Scene Control', desc: 'Effortlessly switch between scenes, manage sources, and adjust overlays in real-time to deliver a flawless, professional stream.' },
      { title: 'AI-Powered Streaming Assistant', desc: 'An intelligent assistant guides you through optimizing settings, preventing issues, and maximizing efficiency for a seamless experience.' },
      { title: 'Unified Alert Dock', desc: 'Stay updated with real-time alerts across all platforms—Twitch, YouTube, Kick, TikTok, and more—all in one centralized dock.' }
    ].map((feature, index) => (
      <Grid item xs={12} md={4} key={index}>
        <Card
          sx={{
            backgroundColor: '#1a1a1a',
            color: '#ffffff',
            boxShadow: '0px 4px 10px rgba(230, 57, 70, 0.4)',
            transition: 'transform 0.3s',
            borderRadius: '12px',
            '&:hover': { transform: 'scale(1.05)', boxShadow: '0px 6px 15px rgba(230, 57, 70, 0.6)' },
          }}
        >
          <CardContent>
            <Typography variant="h6" sx={{ color: '#e63946', fontWeight: 'bold' }} gutterBottom>{feature.title}</Typography>
            <Typography>{feature.desc}</Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
</Container>

{/* Subscription Tiers Section */}
<Container sx={{ textAlign: 'center', marginTop: '50px' }}>
  <Typography variant="h4" sx={{ color: '#e63946', fontWeight: 'bold', marginBottom: 3 }}>Subscription Tiers</Typography>
  <Grid container spacing={4}>
    {[
      { title: 'Free Tier', desc: 'Basic real-time monitoring and essential metrics to keep your stream in check.' },
      { title: 'Premium Tier', desc: 'Adds advanced system metrics, including temperature and GPU usage.' },
      { title: 'Professional Tier', desc: 'Unlocks Discord alerts, OBS optimizations, and priority support for the ultimate streaming experience.' }
    ].map((tier, index) => (
      <Grid item xs={12} md={4} key={index}>
        <Card
          sx={{
            backgroundColor: '#1a1a1a',
            color: '#ffffff',
            boxShadow: '0px 4px 10px rgba(230, 57, 70, 0.4)',
            transition: 'transform 0.3s',
            borderRadius: '12px',
            '&:hover': { transform: 'scale(1.05)', boxShadow: '0px 6px 15px rgba(230, 57, 70, 0.6)' },
          }}
        >
          <CardContent>
            <Typography variant="h6" sx={{ color: '#e63946', fontWeight: 'bold' }} gutterBottom>{tier.title}</Typography>
            <Typography>{tier.desc}</Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
</Container>

{/* Setup Process Section */}
<Container sx={{ textAlign: 'center', marginTop: '50px' }}>
  <Typography variant="h4" sx={{ color: '#e63946', fontWeight: 'bold', marginBottom: 3 }}>Quick Setup Process</Typography>
  <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto' }}>
    After inputting your license key, StreamSentinel validates it against our database. A successful check activates your selected subscription tier, tailoring monitoring features to your needs.
  </Typography>
  <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto' }}>
    <strong>Discord Alerts:</strong> Professional tier users enjoy alerts directly to their Discord channel, with customizable thresholds using the /set_threshold and /preferences commands.
  </Typography>
</Container>

{/* Technical Structure Section */}
<Container sx={{ textAlign: 'center', marginTop: '50px', backgroundColor: '#1a1a1a', padding: '20px', borderRadius: '12px' }}>
  <Typography variant="h4" sx={{ color: '#e63946', fontWeight: 'bold', marginBottom: 3 }}>Technical Directory Structure</Typography>
  <Typography paragraph sx={{ color: '#d4d4d4', maxWidth: '800px', margin: '0 auto' }}>
    <pre style={{ textAlign: 'left', color: '#e63946' }}>
      {`
StreamSentinel/
├── app_logic/               # Main application logic
│   └── main.py              # Entry point of the application
├── config/                  # Configuration files (e.g., Firebase)
│   └── service_account.json  # Firebase Admin SDK credentials
├── discord_bot/             # Discord bot-related code
│   └── discord_alerts.py    # Alert system for Discord
├── firebase/                # Firebase interaction logic
│   └── firebase_manager.py  # Manages Firebase operations
├── metrics/                 # System metrics collection
│   └── system_metrics.py    # Collects CPU, GPU, RAM, and network metrics
├── obs_ws_client/           # OBS WebSocket client for stream monitoring
├── optimize_obs/            # OBS optimization logic
├── ui/                      # User interface for metrics dashboard
│   └── tier_selection.py    # UI for selecting tiers and metrics
      `}
    </pre>
  </Typography>
</Container>

        {/* Footer Links */}
        <Box sx={{ textAlign: 'center', padding: 2, marginTop: 3 }}>
          <Button onClick={() => navigate('/terms')} sx={{ color: '#d4d4d4' }}>Terms of Service</Button>
          <Button onClick={() => navigate('/privacy')} sx={{ color: '#d4d4d4' }}>Privacy Policy</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default HomePage;
