import React, { useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../App.css'; // Import the CSS file for animations

function Cancel() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #0d0d0d, #1a1a1a, #252525)',
      }}
    >
      <Container
        sx={{
          background: 'rgba(29, 29, 29, 0.9)', // Updated semi-transparent dark background for depth
          padding: 4,
          borderRadius: '10px',
          textAlign: 'center',
          color: 'white',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.7)',
        }}
      >
        <Typography variant="h3" gutterBottom sx={{ color: '#e63946', fontWeight: 'bold' }}>
          Subscription Canceled
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#d4d4d4' }}>
          We're sorry to see you go. If you change your mind, you can always try subscribing again.
        </Typography>
        <Typography variant="body2" sx={{ color: '#e63946', fontStyle: 'italic' }}>
          You will be redirected to the home page in 5 seconds.
        </Typography>
      </Container>
    </Box>
  );
}

export default Cancel;